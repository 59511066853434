import React, { useState, useEffect, useContext } from 'react'

import { CommContext } from '../../contexts/commContext'

import moment from 'moment'
import { FadeLoader } from 'react-spinners'
import { toast } from 'react-toastify'

import LoadTranskey from '../../helpers/LoadTranskey'
import GetEvents from '../../helpers/GetEvents'

import EventTile from './EventTile'

export default function EventBookingCalendar(props) {

    const { ioClient, ioConnected } = useContext(CommContext)

    const [events, setEvents] = useState(null)

    useEffect(() => {
        
        async function init() {

            try {

                let params = {
                    "fromDate": "2022-07-22",
                    "toDate": "2022-07-25"
                }
                let events = await GetEvents(params)

                setEvents(events)
            } catch(e) {
                toast.error(LoadTranskey('error'))
                console.log(e)
            }
        }

        init()

    }, [])

    useEffect(() => {

        if(ioClient != null && ioConnected) { ioClient.on('entityAvailabilityUpdated', onEntityAvailabilityUpdated) }

        return () => { if(ioClient != null) { ioClient.off('entityAvailabilityUpdated', onEntityAvailabilityUpdated) } }
    // eslint-disable-next-line
    }, [ioClient, ioConnected])

    const handleSelectEvent = (data) => {

        if(typeof props.onSelectEvent === 'function') props.onSelectEvent(data)
    }

    const onEntityAvailabilityUpdated = async (data) => {

        console.log("update-event-calendar", data)
        
        let params = {
            "fromDate": moment().format("YYYY-MM-DD"),
            "toDate": moment().add(1, 'y').format("YYYY-MM-DD")
        }
        let eventArray = await GetEvents(params)

        for(let i in eventArray) {
           if(eventArray[i].entity.id === data.entityID && moment(eventArray[i].dateAffected).format("YYYY-MM-DD") === data.dateFormatted) {
            eventArray[i].availability = data.availability
            }
        }

        setEvents(eventArray)
    }

    if(events == null) return <div className="w-full h-full flex items-center justify-center"><FadeLoader color={'#05a8cc'} /></div>

    events.sort((a,b) => {

        return moment(a.entity.dateAffected).isBefore((b.entity.dateAffected)) ? -1 : 1
    })

    return(
        <div className="invis appear event-booking-calendar-container lg:mx-auto flex-grow flex flex-wrap items-start">
           {events.length === 0 && LoadTranskey('noEvents')}
           {events.length > 0 && events
           // eslint-disable-next-line
           .filter(event => event.entity.parentEntityID == null)
           .filter(event => event.entity.enabled === 1)
           .map((ev, index) => <EventTile key={index} data={ev} onSelectEvent={handleSelectEvent} />)}
        </div>
    )
}